import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import app_store from './app_store_badge.png';
import banner from './phraseforge_slovak_banner.png';
import exercise_easy from './exercise_easy_cropped.jpg';
import exercise_medium from './exercise_medium_cropped.jpg';
import exercise_hard from './exercise_hard_cropped.jpg';
import explanation from './explanation_cropped.jpg';
import mastery from './mastery_cropped.jpg';
import play_store from './google_play_badge.png';

import { TopicCount } from '../../util/Values';

import './Home.css';

function Home() {
  return (
    <div className="home">
      <Helmet>
        <title>Phraseforge Languages - PhraseForge Slovak</title>
        <meta
          name="description"
          content="PhraseForge Languages develops language learning apps, currently focusing on the Slovak language."
        />
      </Helmet>
      <hr />
      <div className="intro">
        <img
          src={banner}
          className="phraseforge-slovak-graphic"
          alt="PhraseForge Slovak Logo"
        />
        <div className="download-buttons">
          <Link
            target="_blank"
            to="https://apps.apple.com/us/app/phraseforge-slovak/id6504746136"
          >
            <img
              src={app_store}
              className="app-store-badge"
              alt="Download on the App Store"
            />
          </Link>
          <Link
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.djhir3.Slovak"
          >
            <img
              src={play_store}
              className="play-store-badge"
              alt="Get it on Google Play"
            />
          </Link>
        </div>
        <p className="lede">
          <strong>PhraseForge Slovak</strong>, our innovative mobile app
          designed for effective Slovak language learning, is now available on
          Apple&apos;s App Store and the Google Play store!
        </p>
      </div>
      <hr />
      <h2 className="lede">
        <strong>Why choose Phraseforge Slovak?</strong>
      </h2>
      <div className="section">
        <div className="caption">
          <h3>Made for Slovak</h3>
          <p>
            PhraseForge Slovak offers a smooth, incremental introduction
            tailored specifically to the unique features of the Slovak language.
          </p>
          <p>
            Each step is thoughtfully designed to ensure an effective and
            comfortable learning experience, helping you master Slovak with ease
            and confidence.
          </p>
          <p>
            Learn more about how we structure our Slovak course in our page on{' '}
            <Link to="/course-design">course design</Link>.
          </p>
        </div>
        <img
          src={exercise_medium}
          className="screenshot"
          alt="Exercise Example"
        />
      </div>
      <div className="section reverse">
        <div className="caption">
          <h3>Everything Is Explained</h3>
          <p>
            With PhraseForge Slovak, every feature of the language is broken
            down into clear, concise explanations, reinforced by quick exercises
            to solidify your learning.
          </p>
          <p>
            Common mistakes are addressed with immediate, helpful feedback, and
            a comprehensive help system provides detailed guidance whenever you
            need it.
          </p>
          <p>
            Learn more about how we explain new Slovak concepts in our page on{' '}
            <Link to="/lesson-design">lesson design</Link>.
          </p>
        </div>
        <img
          src={explanation}
          className="screenshot"
          alt="Explanation Example"
        />
      </div>
      <div className="section">
        <div className="caption">
          <h3>Measure Your Progress</h3>
          <p>
            Track your Slovak learning progress with our unique mastery rating
            system. It reflects not just lesson completion but also how
            accurately you answer, and your use of the app&apos;s assistance.
          </p>
          <p>
            See your improvement concretely as your rating climbs higher with
            every step forward!
          </p>
          <p>
            Learn more about how we can measure your Slovak progress in our
            article on <Link to="/mastery-ratings">Mastery Ratings</Link>.
          </p>
        </div>
        <img
          src={mastery}
          className="screenshot"
          alt="Mastery Rating Example"
        />
      </div>
      <div className="section reverse">
        <div className="caption">
          <h3>Comprehensive content for beginners ...</h3>
          <p>
            Get started with Slovak with over 5000 exercises across {TopicCount}{' '}
            topics, making use of over 14,000 audio files and over 2000 word
            forms, all designed to comprehensively cover the CEFR A1 level along
            with some A2 content—perfect for beginners eager to master the
            essentials of the Slovak language.
          </p>
          <p>
            Learn more about the coverage of our Slovak course in our{' '}
            <Link to="/language-coverage">language coverage</Link> page.
          </p>
        </div>
        <img
          src={exercise_easy}
          className="screenshot"
          alt="Beginner Exercise Example"
        />
      </div>
      <div className="section">
        <div className="caption">
          <h3>... and we&apos;re just getting started!</h3>
          <p>
            Get ready for extensive new content for Slovak CEFR A2 and B1
            levels, along with exciting new features!
          </p>
          <p>
            We&apos;ll be rolling out <Link to="/updates">updates</Link>{' '}
            regularly with new features and content, so stay tuned!
          </p>
        </div>
        <img
          src={exercise_hard}
          className="screenshot"
          alt="Exercise Example"
        />
      </div>
      <hr />
      <div className="disclaimer">
        Apple is a trademark of Apple Inc. in the U.S. and other countries. App
        Store is a service mark of Apple Inc. Google Play is a trademark of
        Google LLC.
      </div>
    </div>
  );
}

export default Home;
